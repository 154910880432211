import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchJobs } from '../slices/jobs';

import { useDrawer } from '../context/drawerContext';
import JobForm from '../components/jobForm';
import JobOuterTable from '../components/jobOuterTable';

function Jobs() {
  const { openDrawer } = useDrawer();
  const dispatch = useDispatch();
  const wallet = useSelector(state => state.wallet);

  useEffect(() => {
    dispatch(fetchJobs());
    const intervalId = setInterval(() => {
      dispatch(fetchJobs());
    }, 5000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  function createJob() {
    openDrawer(JobForm, {}, 'Create New Job');
  }

  return (
    <div
      id="jobs-container"
      className="network-table-container network-content-container"
    >
      <div className="infrax-subheader">
        <h1 className="infrax-subheader-title">
          InfraX&nbsp;<span>Jobs</span>
        </h1>
        <button
          type="button"
          disabled={!wallet.connected}
          onClick={createJob}
          className="infrax-system-button infrax-system-button-primary"
        >
          + Create Job
        </button>
      </div>
      <JobOuterTable createJob={createJob} wallet={wallet} />
    </div>
  );
}

export default Jobs;
