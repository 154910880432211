import React from 'react';
import { useSelector } from 'react-redux';

function Profile() {
  const wallet = useSelector(state => state.wallet);
  const staking = useSelector(state => state.staking);

  function openBuy() {
    window.open(
      'https://app.uniswap.org/explore/tokens/ethereum/0xe9eccde9d26fcbb5e93f536cfc4510a7f46274f8',
      '_blank'
    );
  }

  return (
    <div
      id="profile-container"
      className="network-table-container network-content-container"
    >
      <div className="container-header staking-row">
        <div className="information-title-fix">
          <div className="information-title">
            InfraX<span>Profile</span>
          </div>
        </div>
        <div className="staking-container staking-action">
          <div className="staking-cell staking-cell-balance">
            <p>
              My <strong>$INFRA</strong> Balance
            </p>
            <div>
              <img src="/assets/logo_no_text_white.svg" alt="" />
              {staking.infraBalance}{' '}
              <span>/ ${staking.infraBalance * wallet.infraxPrice}</span>
            </div>
          </div>
          <div className="staking-cell staking-cell-button">
            <button className="infrax-big-button" onClick={openBuy}>
              Buy
            </button>
          </div>
        </div>
      </div>
      <div className="profile-container">
        <div className="profile-row">
          <div className="profile-cell-image">
            <img src="/assets/images/infrax_spinner.gif" alt="infrax spinner" />
          </div>
          <div className="profile-column">
            <div className="profile-column-cell profile-cell-info">
              <p>
                Total Jobs <strong>0</strong>
              </p>
            </div>
            <div className="profile-column-cell profile-cell-info">
              <p>
                Total Apps <strong>0</strong>
              </p>
            </div>
            <div className="staking-container staking-action profile-column-cell">
              <div className="staking-cell staking-cell-balance">
                <p>
                  My <strong>Residuals</strong>
                </p>
                <div>
                  <img src="/assets/logo_no_text_white.svg" alt="" />0{' '}
                  <span>/ $0.00</span>
                </div>
              </div>
              <div className="staking-cell staking-cell-button">
                <button className="infrax-big-button">Collect</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
