import React from 'react';
import { useSelector } from 'react-redux';

import client, { apolloHeaders } from '../utils/apolloClient';
import { gql } from '@apollo/client';
import { getFilePath } from '../utils/file-utils';
import { useDrawer } from '../context/drawerContext';

import JobForm from './jobForm';
import { INFRAX_TEAM_ADDRESS } from '../utils/constants';
import { getTruncatedWallet } from '../utils/wallet-utils';
import { useNavigate } from 'react-router-dom';

function AppStoreTile({ key, app, canCreateJob }) {
  const { openDrawer } = useDrawer();
  const navigate = useNavigate();
  const connectedWallet = useSelector(state => state.wallet.connectedAccount);
  function abridgeDescription(description) {
    if (description.length > 140) {
      return `${description.slice(0, 140)}...`;
    }
    return description;
  }

  function handleEdit() {
    navigate(`/apps/edit-app/${app.id}`);
  }

  function canEdit() {
    return (
      app.state !== 'HIDDEN' &&
      app.state !== 'PUBLISHED' &&
      connectedWallet === INFRAX_TEAM_ADDRESS
    );
  }

  function canPublish() {
    return app.state !== 'PUBLISHED' && connectedWallet === INFRAX_TEAM_ADDRESS;
  }

  function canHide() {
    return app.state === 'PUBLISHED' && connectedWallet === INFRAX_TEAM_ADDRESS;
  }

  function canShow() {
    return app.state === 'HIDDEN' && connectedWallet === INFRAX_TEAM_ADDRESS;
  }

  async function handlePublish() {
    await client.mutate({
      mutation: gql`
        mutation MyMutation {
          updateApp(
            app: {
              id: "${app.id}"
              state: PUBLISHED
            }
          ) {
            state
            id
          }
        }
      `,
      variables: { app: { id: app.id, state: 'PUBLISHED' } },
      context: apolloHeaders(INFRAX_TEAM_ADDRESS),
    });
  }

  async function handleVis() {
    await client.mutate({
      mutation: gql`
        mutation MyMutation {
          updateApp(
            app: {
              id: "${app.id}"
              state: ${app.state === 'HIDDEN' ? 'PUBLISHED' : 'HIDDEN'}
            }
          ) {
            state
            id
          }
        }
      `,
      variables: {
        app: {
          id: app.id,
          state: app.state === 'HIDDEN' ? 'PUBLISHED' : 'HIDDEN',
        },
      },
      context: apolloHeaders(INFRAX_TEAM_ADDRESS),
    });
  }

  return (
    <div key={key} className="app-tile">
      <div className="app-tile-body">
        <div className="app-tile-image">
          {app?.img?.id ? (
            <img src={getFilePath(app.img.id)} alt={app.name} />
          ) : (
            <img
              src={'/assets/images/infrax_system_placeholder_code.png'}
              alt={app.name}
            />
          )}
        </div>
        <div className="app-tile-header">
          <h4>{app.name}</h4>
          <h5>
            <span>By: </span>
            {app.ethAddress === INFRAX_TEAM_ADDRESS
              ? 'Infrax Team'
              : getTruncatedWallet(app.ethAddress)}
          </h5>
        </div>
        <div className="app-tile-description">
          <p>{abridgeDescription(app.description)}</p>
        </div>
      </div>
      <div className="app-tile-footer">
        <button
          onClick={() => openDrawer(JobForm, { app }, 'Create New Job')}
          className="infrax-system-button infrax-system-button-tertiary"
          disabled={!canCreateJob}
        >
          Create Job
        </button>
        {canPublish() && <button onClick={handlePublish}>publish app</button>}
        {canEdit() && <button onClick={handleEdit}>Edit</button>}
        {canShow() && <button onClick={handleVis}>Show</button>}
        {canHide() && <button onClick={handleVis}>Hide</button>}
      </div>
    </div>
  );
}

export default AppStoreTile;
