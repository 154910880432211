import React, { useEffect } from 'react';
import Navigation from './components/navigation';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchNodes } from './slices/nodes';
import { fetchApps } from './slices/apps';
import { Outlet } from 'react-router-dom';
import { fetchEthPrice, fetchInfraPrice, refreshToken } from './slices/wallet';
import { fetchTestInfraBalance } from './slices/staking';

import Header from './components/header';
import { DrawerProvider } from './context/drawerContext';
import Drawer from './components/drawer';
import ModalProvider from './context/modalContext';

function App() {
  const dispatch = useDispatch();
  const menuOpen = useSelector(state => state.interface.menuOpen);

  useEffect(() => {
    dispatch(fetchNodes());
    dispatch(fetchEthPrice());
    dispatch(fetchApps());
    dispatch(fetchTestInfraBalance());
    // dispatch(fetchInfraBalance());
    dispatch(fetchInfraPrice());
    const token = localStorage.getItem('refreshToken');
    if (token) {
      dispatch(refreshToken());
    }
    const intervalId = setInterval(() => {
      dispatch(fetchNodes());
      dispatch(fetchEthPrice());
      dispatch(fetchApps());
      dispatch(fetchTestInfraBalance());
      //dispatch(fetchInfraBalance());
    }, 30000);

    const reauthIntervalId = setInterval(() => {
      const token = localStorage.getItem('refreshToken');
      if (token) {
        dispatch(refreshToken());
      }
    }, 20 * 60000);

    const infraPriceIntervalId = setInterval(() => {
      dispatch(fetchInfraPrice());
    }, 5 * 60000);

    return () =>
      clearInterval(intervalId, reauthIntervalId, infraPriceIntervalId);
  }, [dispatch]);

  return (
    <>
      <DrawerProvider>
        <ModalProvider />
        <Navigation />
        <div className={`outlet-container ${menuOpen ? 'menu-open' : ''}`}>
          <Header />
          <Outlet />
          <Drawer />
        </div>
      </DrawerProvider>
    </>
  );
}

export default App;
