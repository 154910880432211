import React from 'react';
import { useSelector } from 'react-redux';
import WorldMap from '../components/worldMap';

import InfoTile from '../components/infoTile';

// import HighlightedNodes from "../components/highlightedNodes";

function Network() {
  const nodes = useSelector(state => state.nodes.nodes);
  const wallet = useSelector(state => state.wallet);
  const cdata = { US: nodes.length };

  return (
    <div
      id="info-container"
      className="network-info-container network-content-container"
    >
      <div className="infrax-subheader">
        <h1 className="infrax-subheader-title">
          InfraX&nbsp;<span>Nodes</span>
        </h1>
      </div>
      <div className="infrax-network-layout">
        <div className="network-map-box">
          <WorldMap cdata={cdata} />
        </div>
        <div className="network-information-status">
          <InfoTile
            title="Total Nodes"
            unit=""
            icon="/assets/icons/infrax_system_status.svg"
            alt="Nodes"
            data={nodes.length || 0}
          />
          <InfoTile
            title="Infra Price(USDT)"
            unit="$ "
            icon="/assets/icons/infrax_logo_no_text.svg"
            alt="Infra/USDT"
            data={wallet.infraPrice}
          />
          <InfoTile
            title="ETH Price(USDT)"
            unit="$ "
            icon="/assets/icons/infrax_system_ethereum.svg"
            alt="ETH/USDT"
            data={Number(wallet.ethPrice).toFixed(2)}
          />
          <InfoTile
            title="Infra / Hr / GPU"
            unit=""
            icon="/assets/icons/infrax_logo_no_text.svg"
            alt="Infra/hr/gpu"
            data={0.18}
          />
        </div>
      </div>
      {/* NOTE: Removing Highlighted or "pay as you go nodes" for now - as they have no purpose */}
      {/* <HighlightedNodes /> */}
    </div>
  );
}

export default Network;
