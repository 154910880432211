import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppTable from '../components/appTable';
import EmptyState from '../components/emptyState';

function Apps() {
  const ethAddress = useSelector(state => state.wallet.connectedAccount);
  const [filter, setFilter] = useState('');
  const apps = useSelector(state => state.apps.apps);

  // function getMyApps() {
  //   return apps.filter(app => app.ethAddress === wallet.connectedAddress);
  // }

  return (
    <div
      id="apps-container"
      className="network-table-container network-content-container"
    >
      <div className="infrax-subheader">
        <h1 className="infrax-subheader-title">
          InfraX&nbsp;<span>Apps</span>
        </h1>
        <div className="object-filter">
          <img
            className="search-icon"
            src="/assets/icons/infrax_system_search.svg"
            alt="Search Icon"
          />
          <img
            className="close-icon"
            src="/assets/icons/infrax_system_close.svg"
            onClick={() => setFilter('')}
            alt="Close Icon"
          />
          <input
            type="text"
            value={filter}
            placeholder="Find App"
            onChange={e => setFilter(e.target.value)}
            className="app-filter-input"
          />
        </div>
      </div>
      {apps.length ? (
        <AppTable
          apps={apps}
          filter={filter}
          canCreateJob={!!ethAddress || false}
        />
      ) : (
        <EmptyState
          title="No Apps Found"
          description={
            "Looks like you haven't created any apps yet. Click the button below to create your first app."
          }
          actionText="+ Create App"
        />
      )}
    </div>
  );
}

export default Apps;
