import React from 'react';

import EmptyState from './emptyState';
import ConnectWallet from './connectWallet';
import JobTable from './jobTable';
import { useSelector } from 'react-redux';

function JobOuterTable({ createJob, wallet }) {
  const jobs = useSelector(state => state.jobs.jobs);

  if (wallet.connected) {
    if (jobs.length === 0) {
      return (
        <EmptyState
          title="No Jobs Found"
          description={
            "Looks like you haven't created any jobs yet. Click the button below to create your first job."
          }
          actionText="+ Create Job"
          action={createJob}
        />
      );
    }
    return <JobTable jobs={jobs} />;
  }
  return <ConnectWallet />;
}

export default JobOuterTable;
