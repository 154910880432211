import React from 'react';
import AppStoreTile from './appStoreTile';

function AppTable({ apps, filter, canCreateJob }) {
  return (
    <div id="app-table-populated" className="network-table-body-tiles">
      {apps
        .filter(app =>
          filter ? app.name.toLowerCase().includes(filter.toLowerCase()) : true
        )
        .map((app, index) => (
          <AppStoreTile key={app?.id} app={app} canCreateJob={canCreateJob} />
        ))}
    </div>
  );
}

export default AppTable;
