import { createSlice } from '@reduxjs/toolkit';

export const InterfaceSlice = createSlice({
  name: 'interface',
  initialState: {
    menuOpen: false,
    modalOpen: false,
    modalMessage: '',
    modalImg: '',
    modalTitle: '',
    modalResult: null,
    modalType: 'binary',
  },
  reducers: {
    setMenuOpen: state => {
      state.menuOpen = !state.menuOpen;
    },
    showModal: (state, action) => {
      state.modalOpen = true;
      state.modalMessage = action.payload.message;
      state.modalImg = action.payload.img;
      state.modalTitle = action.payload.title;
      state.modalType = action.payload.type || 'binary';
    },
    hideModal: state => {
      state.modalOpen = false;
      state.message = '';
      state.modalImg = '';
      state.modalTitle = '';
      state.modalType = 'binary';
      state.result = null;
    },
    setModalResult: (state, action) => {
      state.modalResult = action.payload;
    },
  },
});

export const { setMenuOpen, showModal, hideModal, setModalResult } =
  InterfaceSlice.actions;

export default InterfaceSlice.reducer;
