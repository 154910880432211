import React, { useRef } from 'react';
import { VectorMap } from 'react-jvectormap';

function WorldMap({ cdata }) {
  const mapRef = useRef();

  return (
    <div id="world-map">
      <VectorMap
        ref={mapRef}
        style={{ width: '100%', height: '100%' }}
        map="world_mill"
        backgroundColor="transparent"
        zoomButtons={false}
        regionStyle={{
          initial: {
            fill: '#151515',
            'fill-opacity': 1,
            stroke: 'white',
            'stroke-width': 0.5,
            'stroke-opacity': 0.5,
          },
        }}
        series={{
          regions: [
            {
              scale: ['#000000', '#aaaaaa'],
              normalizeFunction: 'linear',
              attribute: 'fill',
              values: cdata,
            },
          ],
        }}
        onRegionTipShow={(e, el, code) => {
          el.html(
            `<img src="assets/icons/menu-icons/Nodes.png">
          <span class="country-label">${el.html()}:</span> ${cdata[code] || 0} Nodes`
          );
        }}
        onRegionClick={(event, code) => {
          // This is populating a million tooltips
          // Because whenever nodes are polled it refreshes the component
          // TODO: Fix this
          // But in the meantime - this hack removes all the tooltips
          // const tooltips = document.querySelectorAll('.jvectormap-tip');
          // if(tooltips[0] && tooltips[0].parentNode) {
          //   for(let i = 1; i < tooltips.length; i++) {
          //     tooltips[i].parentNode.removeChild(tooltips[i]);
          //   }
          // }
          // setTimeout(() => {
          //   navigate(`/network/${code}`)
          // }, 200)
          // NOTE: we don't have a network details page yet :|
        }}
      />
      <div id="note">
        <span>Note:</span> Hover the map to active nodes in each country
      </div>
    </div>
  );
}

export default WorldMap;
