import React from 'react';
import { getFilePath } from '../utils/file-utils';

function JobTableRow({ job }) {
  function getJobResult() {
    if (job?.state === 'FINISHED' && job?.result?.files.length) {
      return (
        <div className="table-cell">
          <a
            target="_blank"
            href={getFilePath(job.result.files[0].id)}
            rel="noreferrer"
          >
            Claim Files
          </a>
        </div>
      );
    }
    if (job?.state === 'FINISHED' && job?.result?.error) {
      return <div className="table-cell"></div>;
    }
    return <div className="table-cell"></div>;
  }

  function abridgeId(id) {
    return id.slice(0, 6) + '...' + id.slice(-6);
  }

  function getCompleteJobStatus(state) {
    if (state === 'FINISHED') {
      if (job?.result?.files?.length) {
        return 'JOB SUCCESSFUL';
      }
      if (job?.result?.error || job?.result?.files.length === 0) {
        return 'FINISHED WITH ERROR';
      }
    }

    switch (state) {
      case 'FINISHING':
        return 'WRAPPING UP...';
      case 'CREATED':
        return 'ASSIGNING NODE...';
      case 'WORKING':
        return 'JOB IN PROGRESS...';
      default:
        return 'UNKNOWN STATUS';
    }
  }

  return (
    <div className="table-row">
      <div className="table-cell">
        {typeof job?.meta['name'] == 'string'
          ? job?.meta['name']
          : 'Undefined Job'}
      </div>
      <div className="table-cell  text-small-light">{abridgeId(job?.id)}</div>
      <div className="table-cell text-small-light">
        {getCompleteJobStatus(job?.state)}
      </div>
      <div className="table-cell text-small-light">{job?.app?.name}</div>
      {getJobResult()}
    </div>
  );
}

export default JobTableRow;
